// sort-imports-ignore
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { Suspense, lazy } from 'react';

import Loading from '@/components/common/loading/Loading';
import LandingPage from '@/pages/landingPage/LandingPage';
import Invalid from '@/pages/invalid/Invalid';
import { ROUTES } from '@/utils/routes';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import '@/theme/variables.css';

// Lazy load components
const Appointment = lazy(() => import('@/pages/appointment/Appointment'));
const Club = lazy(() => import('@/pages/club/Club'));
const DeleteAccount = lazy(() => import('@/pages/legal/DeleteAccount'));
const Group = lazy(() => import('@/pages/group/group'));
const Help = lazy(() => import('@/pages/help/Help'));
const Ios = lazy(() => import('@/pages/ios/Ios'));
const PrivacyPolicy = lazy(() => import('@/pages/legal/PrivacyPolicy'));
const TermsOfService = lazy(() => import('@/pages/legal/TermsOfService'));
const Schedule = lazy(() => import('@/pages/schedule/Schedule'));
const Share = lazy(() => import('@/pages/share/Share'));
const Android = lazy(() => import('@/pages/android/Android'));

setupIonicReact({
  mode: 'ios'
});

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet animated={false}>
          <Suspense fallback={<Loading />}>
            {/** Landing Page **/}
            <Route exact path={ROUTES.ROOT}>
              <LandingPage />
            </Route>
            {/** Invalid Page **/}
            <Route exact path={ROUTES.INVALID}>
              <Invalid />
            </Route>
            {/** Privacy Policy **/}
            <Route exact path={ROUTES.PRIVACY_POLICY}>
              <PrivacyPolicy />
            </Route>
            {/** Terms of Service **/}
            <Route exact path={ROUTES.TERMS_OF_SERVICE}>
              <TermsOfService />
            </Route>
            {/** Schedule Page **/}
            <Route exact path={ROUTES.SCHEDULE}>
              <Schedule />
            </Route>
            {/** Share Page **/}
            <Route exact path={ROUTES.SHARE}>
              <Share />
            </Route>
            {/** Appointment Page **/}
            <Route exact path={ROUTES.APPOINTMENT}>
              <Appointment />
            </Route>
            {/** Help Page **/}
            <Route exact path={ROUTES.HELP}>
              <Help />
            </Route>
            {/** Delete Account Page **/}
            <Route exact path={ROUTES.DELETE_ACCOUNT}>
              <DeleteAccount />
            </Route>
            {/** Download Link: Android **/}
            <Route exact path={ROUTES.ANDROID}>
              <Android />
            </Route>
            {/** Download Link: iOS **/}
            <Route exact path={ROUTES.IOS}>
              <Ios />
            </Route>
            {/** Club Link **/}
            <Route exact path={ROUTES.CLUB}>
              <Club />
            </Route>
            {/** Group Page **/}
            <Route exact path={ROUTES.GROUP}>
              <Group />
            </Route>
          </Suspense>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
