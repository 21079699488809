import { IonIcon, IonImg, IonRouterLink } from '@ionic/react';
import React from 'react';

import footerBackground from '@/assets/images/hero/background.png';
import logo from '@/assets/images/logo/logo-with-icon.png';
import appStoreLogo from '@/assets/images/stores/black/app-store.png';
import googlePlayStoreLogo from '@/assets/images/stores/black/google-play.png';
import Spacer from '@/components/common/spacer/Spacer';
import {
  logoFacebook,
  logoLinkedin,
  logoYoutube,
  mailOutline
} from 'ionicons/icons';

import './Footer.css';

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div
        id="landingPageFooter"
        style={{ backgroundImage: `url(${footerBackground})` }}
      >
        <p id="footerPrompt">
          <span>Level up</span> your dental practice
          <br />
          Get the <span>app</span>.
        </p>
        <Spacer top={16} />
        <div id="footerDownloadButtons">
          <IonRouterLink
            href="https://play.google.com/store/apps/details?id=ph.odonto.dashboard"
            target="_blank"
          >
            <IonImg src={googlePlayStoreLogo} />
          </IonRouterLink>
          <IonRouterLink
            href="https://apps.apple.com/ph/app/odonto/id6737960471"
            target="_blank"
          >
            <IonImg src={appStoreLogo} />
          </IonRouterLink>
        </div>
        <Spacer top={42} />
        <div className="footerLinks">
          <a href="#toFeatures">Features</a>
          <a href="#toFaqs">FAQs</a>
          <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
            Terms
          </a>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
        <Spacer top={16} />
        <div className="stayConnected">
          <div className="socialIcons">
            <a
              href="https://www.facebook.com/OdontoPhilippines"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IonIcon icon={logoFacebook} className="facebookIcon" />
            </a>
            <a
              href="https://www.linkedin.com/in/carlrosales"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IonIcon icon={logoLinkedin} className="linkedinIcon" />
            </a>
            <a
              href="https://www.youtube.com/@OdontoPhilippines"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IonIcon icon={logoYoutube} className="youtubeIcon" />
            </a>
            <a
              href="mailto:hello@odonto.ph"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IonIcon
                icon={mailOutline}
                className="mailIcon"
                color="primary"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
