import { IonRouterLink, IonText } from '@ionic/react';
import React from 'react';

import HeroBackground from '@/assets/images/hero/background.png';
import Spacer from '@/components/common/spacer/Spacer';
import DownloadNow from '@/components/landingPage/downloadNow/DownloadNow';
import Teaser from '@/components/landingPage/teaser/Teaser';

import './Hero.css';

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  const copy = {
    header: (
      <>
        #1 <IonText color="primary">Booking</IonText> App for Dentists
      </>
    ),
    subHeader: (
      <>
        <span className="highlight">Level up</span> your practice! Get the{' '}
        <span className="highlight">app</span>.
      </>
    ),
    button: 'Book a Visit'
  };

  return (
    <div id="hero" style={{ backgroundImage: `url(${HeroBackground})` }}>
      <Teaser />
      <Spacer top={42} bottom={8}>
        <div className="status">
          <div className="statusBadge"></div> DOWNLOAD NOW
        </div>
        <Spacer top={16} bottom={12}>
          <div id="toDownloadNow"></div>
          <h1 className="header">{copy.header}</h1>
        </Spacer>
        <h2 className="subHeader">{copy.subHeader}</h2>
      </Spacer>
      <DownloadNow />
    </div>
  );
};

export default Hero;
