import { IonRouterLink, IonText } from '@ionic/react';
import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import { ROUTES } from '@/utils/routes';

import './Step.css';

interface StepProps {
  step: {
    id: number;
    title: string | React.ReactNode;
    description: string;
  };
}

const Step: React.FC<StepProps> = ({ step }) => {
  const copy = {
    tryItNow: 'Try it now'
  };

  return (
    <div className="step">
      <div className="stepNumberContainer">
        <div className="stepNumber">
          <p>{step.id}</p>
        </div>
        {step.id !== 3 && <div className="stepLine"></div>}
      </div>
      <div className="stepContent">
        <p className="stepTitle">{step.title}</p>
        <Spacer top={8}>
          <p className="stepDetails">
            {step.description}{' '}
            {step.id === 1 && (
              <IonRouterLink href={ROUTES.DEMO} target="_blank">
                {copy.tryItNow}
              </IonRouterLink>
            )}
          </p>
        </Spacer>
      </div>
    </div>
  );
};

export default Step;
