import { IonImg, IonSkeletonText } from '@ionic/react';
import React from 'react';

import image1 from '@/assets/images/carousel/1.png';
import image2 from '@/assets/images/carousel/2.png';
import image3 from '@/assets/images/carousel/3.png';
import image4 from '@/assets/images/carousel/4.png';

import './Features.css';

interface FeaturesProps {
  showHeader?: boolean;
  header?: string;
}

const Features: React.FC<FeaturesProps> = ({
  showHeader = true,
  header = 'Features'
}) => {
  const images = [
    {
      id: 1,
      label: 'Slide 1',
      src: image1
    },
    {
      id: 2,
      label: 'Slide 2',
      src: image2
    },
    {
      id: 3,
      label: 'Slide 3',
      src: image3
    },
    {
      id: 4,
      label: 'Slide 4',
      src: image4
    }
  ];

  return (
    <div id="features">
      {showHeader && (
        <div className="header">
          <div className="headerBadge"></div> {header}
        </div>
      )}
      <div id="carousel">
        <div className="imagesSlide">
          <div className="carouselContainer">
            {images.map((image) => (
              <div key={image.id} className="image">
                <IonSkeletonText animated className="skeleton" />
                <IonImg src={image.src} alt={image.label} />
              </div>
            ))}
          </div>
        </div>

        <div className="imagesSlide">
          <div className="carouselContainer">
            {images.map((image) => (
              <div key={image.id} className="image">
                <IonSkeletonText animated className="skeleton" />
                <IonImg src={image.src} alt={image.label} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="toFaqs"></div>
    </div>
  );
};

export default Features;
