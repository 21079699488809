import { IonText } from '@ionic/react';
import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import Step from '@/components/landingPage/howItWorks/step/Step';

import './HowItWorks.css';

interface HowItWorksProps {
  showHeader?: boolean;
  header?: string;
}

const HowItWorks: React.FC<HowItWorksProps> = ({
  showHeader = true,
  header = 'How It Works'
}) => {
  const steps = [
    {
      id: 1,
      title: (
        <>
          Share your <IonText color="primary">booking</IonText> link
        </>
      ),
      description:
        'Patients can now easily book appointments from your available schedule.'
    },
    {
      id: 2,
      title: (
        <>
          Receive <IonText color="primary">appointment</IonText> requests
        </>
      ),
      description:
        "Simply approve or decline appointment requests and we'll handle the rest."
    },
    {
      id: 3,
      title: (
        <>
          Automated <IonText color="primary">patient</IonText> reminders
        </>
      ),
      description:
        'Both you and your patients will receive notifications and reminders for upcoming appointments.'
    }
  ];

  return (
    <div id="howItWorks">
      {showHeader && (
        <div className="header">
          <div className="headerBadge"></div> {header}
        </div>
      )}
      <Spacer top={16} />
      <div className="steps">
        {steps.map((step) => (
          <Step key={step.id} step={step} />
        ))}
      </div>
      <div id="toFeatures"></div>
    </div>
  );
};

export default HowItWorks;
