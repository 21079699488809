import React from 'react';

import App from './App';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';

const postHogOptions = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  session_recording: {
    maskTextSelector: '.sensitive'
  }
};

const container = document.getElementById('root');
const root = createRoot(container!);

const app = import.meta.env.PROD ? (
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={postHogOptions}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
) : (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

root.render(app);
