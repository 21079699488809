import { IonImg, IonRouterLink } from '@ionic/react';
import React from 'react';

import appStoreLogo from '@/assets/images/stores/black/app-store.png';
import googlePlayStoreLogo from '@/assets/images/stores/black/google-play.png';

import './DownloadNow.css';

const DownloadNow: React.FC = () => {
  return (
    <div id="downloadNow">
      <div id="downloadButtons">
        <IonRouterLink
          href="https://play.google.com/store/apps/details?id=ph.odonto.dashboard"
          target="_blank"
        >
          <IonImg src={googlePlayStoreLogo} />
        </IonRouterLink>
        <IonRouterLink
          href="https://apps.apple.com/ph/app/odonto/id6737960471"
          target="_blank"
        >
          <IonImg src={appStoreLogo} />
        </IonRouterLink>
      </div>
      <div id="requestDemoPrompt">
        <p>See Odonto in action.</p>{' '}
        <IonRouterLink href="https://m.me/OdontoPhilippines" target="_blank">
          Request a demo
        </IonRouterLink>
      </div>
    </div>
  );
};

export default DownloadNow;
