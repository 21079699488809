import React from 'react';

import teaser from '@/assets/videos/teaser.mp4';

import './Teaser.css';

const Teaser: React.FC = () => {
  return (
    <div id="landingPageTeaser">
      <video
        src={teaser}
        id="landingPageTeaserVideo"
        autoPlay
        muted
        loop
        playsInline
        data-object-fit="cover"
        data-wf-ignore="true"
      />
    </div>
  );
};

export default Teaser;
