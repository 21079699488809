import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonRouterLink
} from '@ionic/react';
import React from 'react';

import './Faqs.css';

interface FaqsProps {
  showHeader?: boolean;
  header?: string;
}

const Faqs: React.FC<FaqsProps> = ({ showHeader = true, header = 'FAQs' }) => {
  const faqs = [
    {
      id: 1,
      question: 'What is Odonto?',
      answer: (
        <>
          Odonto is the #1 booking app designed to make appointment scheduling
          easier for dentists.
        </>
      )
    },
    {
      id: 2,
      question: 'How can Odonto help my practice?',
      answer: (
        <>
          <span className="underline">Less admin work</span>
          <ul>
            <li>
              Automate routine tasks and reduce paperwork, allowing you to spend
              more time on patient care.
            </li>
          </ul>
          <span className="underline">All-in-one app</span>
          <ul>
            <li>View and manage your schedule in one place.</li>
          </ul>
          <span className="underline">
            Reduce no-shows and increase revenue
          </span>
          <ul>
            <li>
              Odonto reduces no-shows and last-minute cancellations down to
              0.1%, saving you up to ₱20,000 monthly in lost income.
            </li>
          </ul>
          <span className="underline">Free booking website</span>
          <ul>
            <li>Get a free booking website when you sign up.</li>
          </ul>
          <span className="underline">Stress-free scheduling</span>
          <ul>
            <li>
              Patients can book appointments directly via a booking link,
              eliminating the need for back-and-forth conversations just to find
              an available schedule.
            </li>
          </ul>
          <span className="underline">More time for what matters</span>
          <ul>
            <li>
              We'll take care of your schedule so you can focus on your
              patients, family, and yourself.
            </li>
          </ul>
        </>
      )
    },
    {
      id: 3,
      question: 'How much does Odonto cost?',
      answer: (
        <>
          Enjoy Odonto for just <span>₱999 per month</span> after a{' '}
          <span>1-month free trial</span>, with no lock-in period!
          <ul>
            <li>Unlimited appointments</li>
            <li>Unlimited reminders</li>
            <li>
              <IonRouterLink href="#toFeatures">
                Every feature in Odonto
              </IonRouterLink>
            </li>
            <li>Exclusive calendar app</li>
            <li>Exclusive booking website</li>
          </ul>
        </>
      )
    },
    {
      id: 4,
      question: 'How can seniors and PWDs book their appointments via Odonto?',
      answer: (
        <>
          The Odonto app includes a manual booking feature, allowing dentists or
          receptionists to assist seniors and PWDs in booking their
          appointments.
          <br />
          <br />
          This ensures that everyone can access the dental care they need, even
          if they are unable to book independently.
        </>
      )
    },
    {
      id: 5,
      question: 'How can I transfer my existing appointments to Odonto?',
      answer: (
        <>
          Our support team can help transfer your existing appointments to
          Odonto. Simply{' '}
          <IonRouterLink href="https://odonto.ph/help">
            contact us
          </IonRouterLink>{' '}
          to get started with the transfer process.
        </>
      )
    },
    {
      id: 6,
      question: 'How does Odonto handle privacy and security?',
      answer: (
        <>
          Odonto takes your privacy and security seriously. We use
          industry-standard encryption and secure servers to protect your data.
          <br />
          <br />
          Your information is stored securely and will not be shared with any
          third parties.
        </>
      )
    },
    {
      id: 7,
      question: 'What kind of customer support does Odonto offer?',
      answer: (
        <>
          Odonto offers 24/7 customer support to assist you with any questions
          or issues you may have.
        </>
      )
    }
  ];

  return (
    <div id="faqs">
      {showHeader && (
        <div className="header">
          <div className="headerBadge"></div> {header}
        </div>
      )}
      <IonAccordionGroup id="questions" value="1" multiple={true}>
        {faqs.map((faq) => (
          <IonAccordion
            key={faq.id}
            className="questionContainer"
            value={`${faq.id}`}
          >
            <IonItem className="question" slot="header">
              <IonLabel>{faq.question}</IonLabel>
            </IonItem>
            <div className="answer" slot="content">
              {faq.answer}
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  );
};

export default Faqs;
