import { IonImg, IonSkeletonText } from '@ionic/react';
import React from 'react';

import citiSmiles from '@/assets/images/clinics/citi-smiles.jpg';
import dentalCritique from '@/assets/images/clinics/dental-critique.png';
import elite from '@/assets/images/clinics/elite.jpg';
import happyGreen from '@/assets/images/clinics/happy-green.jpg';
import ikeda from '@/assets/images/clinics/ikeda.jpg';
import nano from '@/assets/images/clinics/nano.jpg';
import nunez from '@/assets/images/clinics/nunez.jpg';
import odontique from '@/assets/images/clinics/odontique.jpg';
import saveteeth from '@/assets/images/clinics/saveteeth.png';
import sonreir from '@/assets/images/clinics/sonreir.jpg';
import zubieto from '@/assets/images/clinics/zubieto.png';

import './TrustedBy.css';

interface FeaturesProps {
  showHeader?: boolean;
  header?: string;
}

const TrustedBy: React.FC<FeaturesProps> = ({
  showHeader = true,
  header = 'Trusted By'
}) => {
  const clinics = [
    {
      id: 1,
      label: 'Ikeda',
      src: ikeda
    },
    {
      id: 2,
      label: 'Nano',
      src: nano
    },
    {
      id: 3,
      label: 'Sonreir',
      src: sonreir
    },
    {
      id: 4,
      label: 'SaveTeeth',
      src: saveteeth
    },
    {
      id: 5,
      label: 'Zubieto',
      src: zubieto
    },
    {
      id: 6,
      label: 'Citi Smiles',
      src: citiSmiles
    },
    {
      id: 7,
      label: 'Nunez',
      src: nunez
    },
    {
      id: 8,
      label: 'Happy Green',
      src: happyGreen
    },
    {
      id: 9,
      label: 'Elite',
      src: elite
    }
  ];

  return (
    <div id="trustedBy">
      {showHeader && (
        <div className="header">
          <div className="headerBadge"></div> {header}
        </div>
      )}
      {/* 1st Row */}
      <div className="clinics">
        <div className="clinicsSlide">
          <div className="clinicsContainer">
            {clinics.map((clinic) => (
              <div key={clinic.id} className="image">
                <IonSkeletonText animated className="skeleton" />
                <IonImg src={clinic.src} alt={clinic.label} />
              </div>
            ))}
          </div>
        </div>

        <div className="clinicsSlide">
          <div className="clinicsContainer">
            {clinics.map((clinic) => (
              <div key={clinic.id} className="image">
                <IonSkeletonText animated className="skeleton" />
                <IonImg src={clinic.src} alt={clinic.label} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* 2nd Row */}
      {/* TODO: Add 2nd row when there are more than 16 clinics */}
      {/* <div className="clinics">
        <div className="clinicsSlide">
          <div className="clinicsContainer">
            {clinics
              .filter((clinic) => clinic.id > clinics.length / 2)
              .map((clinic) => (
                <div key={clinic.id} className="image">
                  <IonSkeletonText animated className="skeleton" />
                  <IonImg src={clinic.src} alt={clinic.label} />
                </div>
              ))}
          </div>
        </div>

        <div className="clinicsSlide">
          <div className="clinicsContainer">
            {clinics
              .filter((clinic) => clinic.id > clinics.length / 2)
              .map((clinic) => (
                <div key={clinic.id} className="image">
                  <IonSkeletonText animated className="skeleton" />
                  <IonImg src={clinic.src} alt={clinic.label} />
                </div>
              ))}
          </div>
        </div>
      </div> */}

      <div id="toHowItWorks"></div>
    </div>
  );
};

export default TrustedBy;
