import { IonContent, IonPage } from '@ionic/react';

import InvalidComponent from '@/components/common/invalid/Invalid';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';

import './Invalid.css';

const Invalid: React.FC = () => {
  return (
    <IonPage id="default">
      <IonContent>
        <Padding>
          <Logo navigateToRootOnClick />
          <InvalidComponent />
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default Invalid;
