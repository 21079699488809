import { IonImg } from '@ionic/react';
import React from 'react';

import logo from '@/assets/images/logo/logo-with-icon.png';
import Spacer from '@/components/common/spacer/Spacer';

import './Header.css';

const Header: React.FC = () => {
  const bloop = (e: React.MouseEvent<HTMLIonImgElement, MouseEvent>) => {
    const imageElement = e.currentTarget;
    if (imageElement.classList.contains('bloop')) {
      return;
    }
    imageElement.classList.add('bloop');
    setTimeout(() => {
      imageElement.classList.remove('bloop');
    }, 1000);
  };

  return (
    <>
      <div id="landingPageHeader">
        <IonImg
          src={logo}
          alt="Logo"
          id="landingPageHeaderImage"
          onClick={bloop}
        />
      </div>
      <Spacer bottom={66} />
    </>
  );
};

export default Header;
