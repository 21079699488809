import { IonContent, IonPage } from '@ionic/react';

import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Faqs from '@/components/landingPage/faqs/Faqs';
import Features from '@/components/landingPage/features/Features';
import Footer from '@/components/landingPage/footer/Footer';
import Header from '@/components/landingPage/header/Header';
import Hero from '@/components/landingPage/hero/Hero';
import HowItWorks from '@/components/landingPage/howItWorks/HowItWorks';
import TrustedBy from '@/components/landingPage/trustedBy/TrustedBy';

import './LandingPage.css';

const LandingPage: React.FC = () => {
  return (
    <IonPage id="landingPage">
      <IonContent>
        <Padding>
          <Header />
          <Hero />
          <Spacer top={56} />
          <div id="toTrustedBy"></div>
          <TrustedBy />
          <HowItWorks />
          <Features />
          <Faqs />
          <Footer />
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage;
